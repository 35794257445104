<template>
  <div class="component-fullwidth">
    <div class="forum-cat flex-container flex-2-tier no-padding no-margin">
      <div class="elem-flex">
        <div class="title-page">
          Forum
        </div>
        <div class="subtitle-page">
          Categories
        </div>
      </div>
    </div>
    <div class="inner-container">
      <div id="listcategories" class="cont-forum">
        <category v-for="cat in categories" :key="cat._id" :forumCategory="cat" />
      </div>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import Category from '@/components/UniversalComponents/Forum/category.vue';

export default {
  name: 'ForumCategories',
  components: {
    Category,
  },
  data() {
    return {
      categories: [],
    };
  },
  mounted() {
    this.$api.get('forum').then((res) => {
      this.categories = res.data;
      const ps = new PerfectScrollbar('#listcategories', {
        wheelPropagation: false,
        wheelSpeed: 1,
        suppressScrollX: true,
      });
      ps.update();
    });
  },

};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .forum-cat{
    .subtitle-page{
      margin-top: 5vh;
      margin-bottom: 1vh;
      color: $logo_color_dark;
      font-weight: 600;
    }
  }
  .cont-forum{
    padding: 0.5vh 1vh 0.5vh 0;
    position: relative;
    overflow: hidden;
    height: 71.5vh;
    width: calc(100% - 1.5vh);
  }
</style>
