<template>
  <div class="line-forum radius forum-cat box-shadow" @click="goTo">
    <div class="head">
      <div class="cont-title">
        <div class="title">
          {{forumCategory.title}}
        </div>
        <div class="desc">
          Description
        </div>
      </div>
    </div>
    <!-- {{forumCategory}} -->
    <div class="cont-count">
      <div class="elem-count radius-small">
        <div class="info-data">
          {{forumCategory.threads.length}}
        </div>
        <div class="info-title">
          Threads
        </div>
      </div>
      <div class="elem-count radius-small" v-if="threads[0]">
        <div class="info-data">
          {{threads[0].posts.length}}
        </div>
        <div class="info-title">
          Posts
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CategoryDisplay',
  props: ['forumCategory'],
  data() {
    return {
      threads: [],
    };
  },
  mounted() {
    this.$api.get(`forum/category/${this.forumCategory._id}`).then((res) => {
      this.threads = res.data.threads;
    });
  },
  methods: {
    goTo() {
      this.$router.push(`/forum/category/${this.forumCategory._id}`);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";
.forum-cat{
}
</style>
